var render = function render(){var _vm=this,_c=_vm._self._c;return _c('hk-table',{attrs:{"items":_vm.rolls,"columns":_vm.rollColumns,"showHeader":false},scopedSlots:_vm._u([{key:"roll",fn:function(data){return _c('div',{staticClass:"roll"},[_c('span',[_vm._v("\n\t\t\t"+_vm._s(_vm.calcAverage(data.row.dice_type, data.row.dice_count, data.row.fixed_val))+"\n\t\t\t("+_vm._s(data.row.dice_count || "")+_vm._s(data.row.dice_type ? `d${data.row.dice_type}` : ``)+"\n\t\t\t"),(data.row.fixed_val && data.row.dice_count)?[_vm._v("\n\t\t\t\t"+_vm._s(// eslint-disable-next-line vue/no-parsing-error
					data.row.fixed_val < 0
						? `- ${Math.abs(data.row.fixed_val)}`
						: `+ ${data.row.fixed_val}`)+")\n\t\t\t")]:[_vm._v(_vm._s(data.row.fixed_val)+")")]],2),(data.row.options && _vm.versatileRoll(data.row))?_c('span',[_vm._v("\n\t\t\t| "+_vm._s(_vm.versatileRoll(data.row))+"\n\t\t")]):_vm._e(),(data.row.options && Object.values(data.row.options).length > 1)?_c('span',[_c('span',[_vm._v("| ...")]),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle"}},[_vm._v("More than two Options")])],1):_vm._e()])}},{key:"scaling",fn:function(data){return (_vm.scaling && _vm.level !== undefined && data.row.scaling && data.row.scaling.length)?_c('hk-popover',{},[_c('i',{staticClass:"fas fa-chart-line",attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.scalingDesc(data.row.scaling, _vm.scaling, _vm.level))},slot:"content"})]):_vm._e()}},{key:"type",fn:function(data){return _c('span',{},[(_vm.type === 'healing')?_c('span',{staticClass:"healing"},[_c('i',{staticClass:"fas fa-heart",attrs:{"aria-hidden":"true"}}),_vm._v(" Healing\n\t\t")]):(data.row.damage_type)?[_c('span',{class:data.row.damage_type},[_c('i',{class:_vm.damage_type_icons[data.row.damage_type],attrs:{"aria-hidden":"true"}}),_vm._v("\n\t\t\t\t"+_vm._s(data.row.damage_type.capitalize())+"\n\t\t\t\t"),(_vm.versatile)?_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.versatileOptions[1] || "Enter versatile option")+"\n\t\t\t\t")]):_vm._e()],1),(
					_vm.versatile &&
					data.row.versatile_damage_type &&
					data.row.versatile_damage_type !== data.row.damage_type
				)?[_vm._v("\n\t\t\t\t|\n\t\t\t\t"),_c('span',{class:data.row.versatile_damage_type},[_c('i',{class:_vm.damage_type_icons[data.row.versatile_damage_type],attrs:{"aria-hidden":"true"}}),_vm._v("\n\t\t\t\t\t"+_vm._s(data.row.versatile_damage_type.capitalize())+"\n\t\t\t\t\t"),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle"}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.versatileOptions[1] || "Enter versatile option")+"\n\t\t\t\t\t")])],1)]:_vm._e(),_vm._v("\n\t\t\tdamage\n\t\t")]:_vm._e()],2)}},{key:"magical",fn:function(data){return [(data.row.magical || data.row.versatile_magical)?_c('i',{staticClass:"fas fa-sparkles",attrs:{"aria-hidden":"true"}},[_c('q-tooltip',{attrs:{"anchor":"center right","self":"center left"}},[_vm._v(" Magical ")])],1):_vm._e()]}},{key:"fail",fn:function(data){return (!['healing', 'damage'].includes(_vm.type))?[_vm._v("\n\t\t"+_vm._s(_vm.type === "save"
				? `Save: ${_vm.application[data.row.save_fail_mod]}`
				: `Miss: ${_vm.application[data.row.miss_mod]}`)+"\n\t")]:undefined}},{key:"actions",fn:function(data){return _c('div',{staticClass:"actions"},[_c('a',{staticClass:"ml-2 btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.$emit('edit', { roll_index: data.index, roll: data.row })}}},[_c('i',{staticClass:"fas fa-pencil-alt",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Edit")])],1),_c('a',{staticClass:"ml-2 btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.$emit('delete', data.index)}}},[_c('i',{staticClass:"fas fa-trash-alt",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Delete")])],1)])}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }